import React from "react";
import Layout from "../components/Layout";
import Container from "../components/Container";
import { Text } from "@chakra-ui/core";

const NotFoundPage = () => (
  <Layout>
    <Container>
      <Text fontSize="3xl" color="#fff" fontWeight={700} mb={6}>
        Niet gevonden
      </Text>
      <Text fontSize="xl" color="#fff">
        Deze pagina bestaat niet.
      </Text>
    </Container>
  </Layout>
);

export default NotFoundPage;
